document.addEventListener('DOMContentLoaded', () => {
  // open
  const burger = document.querySelector('.mobile-menu__trigger');
  const menu = document.querySelector('.mobile-menu');

  function toggleMenu() {
    menu.classList.toggle('mobile-menu__hidden');
    document.body.classList.toggle('o-hidden');
  }

  if (burger && menu) {
    burger.addEventListener('click', toggleMenu);
  }

  // close
  const close = document.querySelector('.navbar-close');
  const backdrop = document.querySelector('.navbar-backdrop');

  if (close) {
    close.addEventListener('click', toggleMenu);
  }

  if (backdrop) {
    backdrop.addEventListener('click', toggleMenu);
  }
});
